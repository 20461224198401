<template>
  <div class="mx-a mb-16">
    <div class="current-bar pyx mb-16">
      <router-link
        v-for="nav in routerList"
        :key="nav.id"
        :to="nav.path"
        :class="`depth2 fm-16 ${G_NAV_ID.startsWith(nav.id) ? 'selected' : ''}`"
      >
        {{ nav.title }}
      </router-link>
    </div>
    <BasicInfo />
    <BusinessInfo class="mt-1" />
    <BankInfo class="mt-1" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BasicInfo from "@/components/ui/myInfo/BasicInfo";
import BusinessInfo from "@/components/ui/myInfo/BusinessInfo";
import BankInfo from "@/components/ui/myInfo/BankInfo";

export default {
  name: "CompanyPartenerInfo",
  mixins: [],
  components: { BasicInfo, BusinessInfo, BankInfo },

  /*** data ***/
  data() {
    return {
      routerList: [],
      basicData: {},
      businessData: {},
      bankData: {},
    };
  },

  /*** created ***/
  async created() {
    await this.$store.dispatch("A_NAV_SHOW", false);
  },

  /*** mounted ***/
  async mounted() {
    this.routerList.push({
      id: "11-2-1-1",
      path: `/company/partener/info/${this.$route.params.id}`,
      title: "파트너정보",
    });
    console.log(this.routerList)
    this.routerList.push({
      id: "11-2-1-2",
      path: `/company/profile/${this.$route.params.id}`,
      title: "프로필",
    });
  },

  /*** computed ***/
  computed: {
    ...mapGetters(["G_NAV_ID"]),
  },

  /*** watch ***/
  watch: {},

  /*** methods ***/
  methods: {},
};
</script>

<style scoped lang="scss">
</style>
